import React from 'react';

function ListItem({title, subtitle}) {
    return (
        <li className='list-row'>
            <h6 className="list-title">{title}</h6>
            <h6 className='list-subtitle'>{subtitle}</h6>
        </li>
    );
}

export default ListItem;
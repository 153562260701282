import React from 'react';

function MetaDataBlock({title, subheader}) {
    return (
        <div className='meta-data-block'>
            <h4>{title}</h4>
            <p className='meta-data-subheader'>{subheader}</p>
        </div>
    );
}

export default MetaDataBlock;
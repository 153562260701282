import React from 'react';
import ImageCard from "./ImageCard";
import { useParams } from 'react-router-dom';

function FeaturedProjects({title,content}) {
    const { projectName } = useParams();
    
    return (
        (content)?
            <div className="featured-projects-list">
                <h2>{title}</h2>
                {(projectName === content[0].linkName)?null:
                    <ImageCard
                    title={content[0].mainPageHeader}
                    description={content[0].mainPageDescription}
                    image={content[0].mainPageImage}
                    link={content[0].linkName}
                    icon={content[0].mainIcon}
                />
                }
                    <div className="featured-projects-list-row">
                    {(projectName === content[2].linkName)?null:
                        <ImageCard
                        title={content[2].mainPageHeader}
                        description={content[2].mainPageDescription}
                        image={content[2].mainPageImage}
                        link={content[2].linkName}
                        icon={content[2].mainIcon}
                        />
                    }
                    {(projectName === content[3].linkName)?null:
                        <ImageCard
                        title={content[3].mainPageHeader}
                        description={content[3].mainPageDescription}
                        image={content[3].mainPageImage}
                        link={content[3].linkName}
                        icon={content[3].mainIcon}
                        />
                    }
                    </div>
                    {(projectName === content[1].linkName)?null:
                        <ImageCard
                        title={content[1].mainPageHeader}
                        description={content[1].mainPageDescription}
                        image={content[1].mainPageImage}
                        link={content[1].linkName}
                        icon={content[1].mainIcon}
                        />
                    }
            </div>
        :null
            
    );
}

export default FeaturedProjects;
import React,{useContext} from 'react';
import AboutMe from '../components/AboutMe';
import MyContext from '../util/MyContext';

function AboutPage() {
    const data = useContext(MyContext);
    return (
        <div className='container about'>
            <div className="container-item">
                <AboutMe content={(data!=null)?data["aboutMe"]:null}/>
            </div>          
        </div>
    );
}

export default AboutPage;
import React from 'react';
import Button from './Button';
import HeroImage from '../svg/HeroImage';
function HeroSection(props) {
    return (
        <section className="hero-section">
                <div className="hero-text-container">
                <h1>Crafting User Experiences That Matter</h1>
                <p>Hi👋, I'm Min. I merge creativity with utility<br/>to redefine technology experiences.</p>
                </div>
                <div className="button-group">
                    <Button
                    text={"Explore Projects ↓"}
                    type={"primary"}
                    link={"/projects"}
                    />
                    <Button
                    text={"See My Resume →"}
                    type={"secondary"}
                    link={"https://drive.google.com/file/d/1NXc5mbI1w4BSPaY36pv6Zf9whCO6ZrHZ/view?usp=sharing"}/>
                </div>
                <div className="hero-image-container">
                    <HeroImage/>
                </div>
            </section>
    );
}

export default HeroSection;
import HeroSection from "../components/HeroSection";
import FeaturedProjects from "../components/FeaturedProjects";
import ProjectList from "../components/ProjectList";
import AboutMe from "../components/AboutMe";
import { useContext } from "react";
import MyContext from "../util/MyContext";

function HomePage() {
    const data = useContext(MyContext);
    return (
        (data)?
        <div className="container">
        <div className="hero-container-item">
            <HeroSection/>
        </div>
        <div className="container-item">
            <FeaturedProjects title={"Featured projects"} content={data.projects}/>
            <hr/>
            <ProjectList content={data.projects} title={"Other projects"} numItems={3} seeAllButton={true}
            includeFeatured={false}/>
        </div>
        <div className="container-item">
            <AboutMe content={(data!=null)?data["aboutMe"]:null}/>
        </div>           
    </div>
        :
        null
    );
}

export default HomePage;
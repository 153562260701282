import './App.css';
import Header from './components/Header';
import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import AboutPage from './pages/AboutPage';
import Footer from "./components/Footer";
import PortfolioListPage from './pages/PortfolioListPage';
import PortfolioPage from './pages/PortfolioPage';
import dataJson from './data.json';
import { useEffect, useState } from 'react';
import MyContext from './util/MyContext';

function App() {
  const [data,setData] = useState({});
  useEffect(() => {
    setData(dataJson);
  }, []);

  return (
    <MyContext.Provider value={data}>
    <div className="App">
      <Header/>
        <Routes>
        <Route
        path="/"
        element={<HomePage/>}/>
        <Route path="/projects" element={<PortfolioListPage/>} />
        <Route
        path="about"
        element={<AboutPage />} />
        <Route
        path="/:projectName"
        element={<PortfolioPage/>}/>
        <Route path="/404" element={<NotFoundPage />} />
        <Route path="*" element={<NotFoundPage />} />
        </Routes>
      <Footer/>
    </div>
    </MyContext.Provider>
  );
}

export default App;

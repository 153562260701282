import React from 'react';

function CardAndDescription({title,description, image}) {
    return (
        <div className='card-and-description'>
            <div className="image-container">
                <img src={image}alt={title} />
            </div>
            <div className="text-container">
                <h5>{title}</h5>
                <p className='body-2'>{description}</p>
            </div>
        </div>
    );
}

export default CardAndDescription;
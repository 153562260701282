import React from 'react';

function InsightBlock({title, body}) {
    return (
        <div className='insight-block'>
            <h3>{title}</h3>
            <p>{body}</p>
        </div>
    );
}

export default InsightBlock;
import React from 'react';
import {Link} from 'react-router-dom';

function NotFoundPage(props) {
    return (
        <div className='error-message-container'>
        <h1>Oops! Page Not Found</h1>
        <p>We're sorry, but the page you're looking for doesn't exist or may have been moved. Please check the URL for any typos, or you can return to the <Link to='/' aria-label='go back to home page'>homepage</Link> to find what you're looking for.</p>
        {/* You can customize the error message and UI here */}
      </div>
    );
}

export default NotFoundPage;
import React from 'react';

function SummaryBlock({summary}) {
    return (
        <div className='summary-block'>
            <p>{summary}</p>
        </div>
    );
}

export default SummaryBlock;
import React from 'react';
import { Link } from 'react-router-dom';

function Button({text, type, link}) {
    return (
        <Link to={link}>
            <button className={(type === "primary")?"primary-button":(type == "secondary")?"secondary-button":"tertiary-button"}>{text}</button>
        </Link>
    );
}

export default Button;
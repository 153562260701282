import React from 'react';
import List from './List';
import Button from './Button';
import AboutMeImage from '../svg/AboutMeImage';

function AboutMe({content}) {
    return (
        <section className='about-container'>
            <div className='about-header'>
                <div>
                    <div className='text-container'>
                    <h2>About me</h2>
                    <p>My design career is marked by success in developing experiences for massive user bases. Skilled at quickly adapting to a wide range of topics, I consistently apply a user-first approach to create intuitive and effective digital solutions.</p>
                    </div>
                    <Button
                    text={"See Resume →"}
                    type={"primary"}
                    link={"https://drive.google.com/file/d/1NXc5mbI1w4BSPaY36pv6Zf9whCO6ZrHZ/view?usp=sharing"}
                    />
                </div>
                <div>
                    <AboutMeImage/>
                </div>
            </div>
            {
                (content!=null)?
                <div className='about-summary'>
                <List title={"Experience"} content={content["Experience"]}/>
                <List title={"Honors & Awards"} content={content["Honors & Awards"]}/>
                </div>
                :null
            }
            
        </section>
    );
}

export default AboutMe;
import React from 'react';

function Footer(props) {
    return (
       <footer>
        <div>
            <h6>Min Jea Seo</h6>
        </div>
        <div className='footer-contact'>
            <div>
                <span>✉️</span>
                <p>seominjea1942@gmail.com</p>
            </div>
            <div>
                <span>🧑‍💻</span>
                <p>www.linkedin.com/in/mseo2</p>
            </div>
        </div>
       </footer>
    );
}

export default Footer;
import React from 'react';
import { Link } from 'react-router-dom';
import HomeIcon from '../svg/HomeIcon';

function Header(props) {
    return (
        <nav className='header'>
        <ul>
            <div>
                <li><Link to="/"><HomeIcon/></Link></li>
            </div>
            <div className='navItems'>
                <li><Link to="/projects">Projects</Link></li>
                <li><Link to="/about">About</Link></li>
            </div>
        </ul>
      </nav>
    );
}

export default Header;
import {useContext} from 'react';
import FeaturedProjects from '../components/FeaturedProjects';
import ProjectList from '../components/ProjectList';
import MyContext from '../util/MyContext';
function PortfolioListPage() {
    const data = useContext(MyContext);
    return (
        <div className="container">
            {(data&&data.projects)?
            <div className="container-item">
                <FeaturedProjects title={"Featured projects"}content={data.projects}/>
                <hr/>
                <ProjectList
                content={data.projects}
                title={"Other projects"}
                numItems={Object.values(data.projects).length}
                includeFeatured={false}
                seeAllButton={false}/>
            </div>
            :null}
        </div>
    );
}

export default PortfolioListPage;
import React from 'react';
import ListItem from './ListItem'
function List({title, content}) {
    let renderListContent = () =>{
        //iterate based o the listContent
        return Object.values(content).map((item,index)=>{
            return<ListItem key={`${index}+${item[0]}`} title={item[0]} subtitle={item[1]}/>
        })
    }
    return (
        <div className={`list-container-${title.split(' ')[0].toLowerCase()}`}>
            <h4>{title}</h4>
            <ul className='list-rows'>
                {renderListContent()}
            </ul>
        </div>
    );
}

export default List;
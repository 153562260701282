import React from 'react';

function ImageCardNoText({image}) {
    return (
        <picture className='imagecard-no-text'>
            <img src={`./${image}`} alt="" />
        </picture>
    );
}

export default ImageCardNoText;
import React from 'react';

function HomeIcon(props) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_30_307)">
        <path d="M1 10L12 2L23 10" stroke="#232931" strokeWidth="1.5" strokeLinecap="square"/>
        <path d="M3 9L3 22H21V9" stroke="#232931" strokeWidth="1.5" strokeLinecap="square"/>
        <path d="M9 21V14H15V21" stroke="#232931" strokeWidth="1.5" strokeLinecap="round"/>
        </g>
        <defs>
        <clipPath id="clip0_30_307">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
        </svg>
    );
}

export default HomeIcon;
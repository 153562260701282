import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import  {useNavigate} from 'react-router-dom';
import ImageCardNoText from '../components/ImageCardNoText';
import SummaryBlock from '../components/SummaryBlock';
import InsightBlock from '../components/InsightBlock';
import MyContext from "../util/MyContext";
import FeaturedProjects from '../components/FeaturedProjects';
import ProjectList from '../components/ProjectList';
import MetaDataBlock from '../components/MetaDataBlock';
import LinkBlock from '../components/LinkBlock';
import VideoBlock from '../components/VideoBlock';
function PortfolioPage() {
    const data = useContext(MyContext);
    const { projectName } = useParams();
    const navigate = useNavigate();
    const [projectData, setProjectData] = useState(null);
    

      // Check for project existence when data or projectName changes
      useEffect(() => {
        if(Object.keys(data).length !== 0){
            const projectIndex = isProjectExist(projectName, data);
            if (projectIndex !== -1) {
                // If project exists, set the project data
                const projectList = Object.values(data)[0];
                const projectInfo = Object.values(projectList)[projectIndex];
                setProjectData(projectInfo);
            } else {
                // Navigate to 404 if the project does not exist
                navigate('/404');
            }
        }
       
    }, [projectName, data, navigate]);

    /**
     * Check if the project is in the data or not.
     * Return -1 if there is no existing project, return the index of the data if the project exists.
     */
    function isProjectExist(projectName, data) {
        if (Object.keys(data).length !== 0) {
            const projectList = Object.values(data)[0];
            return Object.values(projectList).findIndex(project => project.linkName === projectName);
        }
        return -1;
    }

    // Render null while data is being fetched or if no project data has been set
    if (!projectData) return null;

    // Safe check for insights data before rendering
    const insightsAvailable = projectData?.insights?.length > 0;
    const renderInsightBlocks = ()=>{
        return projectData.insights.map((insight,index)=>{
            return(
                <section className="pf-content-section" key={`insight+${index}`}>
                    <InsightBlock
                        title={insight[0]}
                        body={insight[1]}
                    />
                    {(isProjectExist(projectName, data)===5)?
                    <VideoBlock
                    source={projectData.insightsImage[index]}
                    />
                    :(isProjectExist(projectName, data)===1&&projectData.insights.length === index+1)?
                        <VideoBlock
                        source={projectData.insightsImage[index]}
                        index={1}/>
                    :
                    ((index === projectData.insights.length-1)&&([0,3].includes(isProjectExist(projectName, data))))?
                    <div className='two-result-images'>
                        <ImageCardNoText
                            image={projectData.insightsImage[index]}
                        />
                        <ImageCardNoText
                            image={projectData.insightsImage[index+1]}
                        />
                    </div>
                    :
                    <ImageCardNoText
                        image={projectData.insightsImage[index]}
                    />
                    }
                </section>
            )
        })
    };
    return (
        <div className="container">
        {(projectData)?(
            <div className='container-item'>
                <section className='pf-hero-section'>
                    <h1>{projectData.header}</h1>
                    {([0,6,9].includes(isProjectExist(projectName, data)))?
        <LinkBlock index ={isProjectExist(projectName, data)}/>
        :null}
                    <div className='meta-data-rows'>
                        <div className='meta-data-row-1'>
                            <div>
                                <MetaDataBlock
                                title={projectData.metaData.year}
                                subheader={"Year"}
                                />
                                <MetaDataBlock
                                title={projectData.metaData.duration}
                                subheader={"Duration"}
                                />
                            </div>
                            <div>
                                <MetaDataBlock
                                title={projectData.metaData.company}
                                subheader={"Company"}
                                />
                                <MetaDataBlock
                                title={projectData.metaData.role}
                                subheader={"Role"}
                                />
                            </div>
                        </div>
                        <div className='meta-data-row-2'>
                            {(projectData.metaData.collaborationDetails)?
                            <MetaDataBlock
                            title={projectData.metaData.collaborationDetails}
                            subheader={"Collaboration Details"}/>
                            :null}
                        </div>
                    </div>
                    <ImageCardNoText image={projectData.heroImage}/>
                    <SummaryBlock summary={projectData.summary}/>
                    </section>
                    {insightsAvailable? (renderInsightBlocks()
              ) : null}
            </div>
            
        ):null}
    
        <div className="container-item">
        <hr />
            <FeaturedProjects title={"Other projects"} content={data.projects}/>
            <hr/>
            <ProjectList content={data.projects} numItems={3} seeAllButton={true}
            includeFeatured={false}/>
        </div>
        
        </div>
    );
}

export default PortfolioPage;
import React from 'react';

function VideoBlock({source, index}) {
    return (
        <div className={`video-block ${(index===1)?"denali":''}`}>
            <video aria-label="A feature video" autoPlay loop playsInline muted>
                <source src={source} type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
        </div>
    );
}

export default VideoBlock;
/**
 * Proejct list is using Card List view to show numbers of projects as privew. And, it has button to bring users to PortfolioListPage to allow users to see all potfolios.
 */

import React from 'react';
import CardAndDescription from './CardAndDescription';
import Button from './Button';
import { Link } from 'react-router-dom';
import MyContext from '../util/MyContext';

function ProjectList({content, title, numItems, seeAllButton, includeFeatured}) {
    let CardListViewList =()=>{
        let components = [];
        if(content){
            for(let i = 0; i<Object.values(content).length;i++){
                //if includeFeatured is true
                if(includeFeatured){
                    components.push
                    (
                        <Link key={`${title}+${i}`} to={`/${content[i].linkName}`}>
                    <CardAndDescription 
                        title={content[i].mainPageHeader}
                        description={content[i].mainPageDescription}
                        image={content[i].mainPageImage}
                        key={i}/>
                        </Link>
                    );
                } else {
                    if(content[i].featured === false){
                        components.push(
                            <Link key={`${title}+${i}`} to={`/${content[i].linkName}`}>
                        <CardAndDescription key={i}
                            title={content[i].mainPageHeader}
                            description={content[i].mainPageDescription}
                            image={content[i].mainPageImage}/>
                            </Link>
                            );
                    }
                }
            }
        }
        return components.slice(0,numItems);
    }
    return (
        <div className='project-list'>
            {(title)?<h3>{title}</h3>:null}
            {CardListViewList()}
            {(seeAllButton)? <Button text="See all projects →" type={"secondary"} link={"/projects"} />:null}
        </div>
    );
}

export default ProjectList;
import React from 'react';

function LinkBlock({index}) {

switch(index){
    case 0:
        return (
            <div className='link-block'>
                <h4>
                    <span>Related news/articles: </span>
                <a href="https://www.forbes.com/sites/dbloom/2023/11/07/yahoo-finance-gets-its-biggest-makeover-in-a-decade/?sh=6e69c6ae2878" rel="noreferrer" target="_blank"> 🔗 Forbes</a> 
                </h4>
            </div>
        );
        case 6:
            return (
                <div className='link-block'>
                    <h4>
                    <span>Related news/articles: </span>
                    <a href="https://designawards.core77.com/Interaction/85686/dot" rel="noreferrer" target="_blank"> 🔗 Core77</a>
                    <a href="https://thelondondesignawards.com/winner-info.php?id=1014" rel="noreferrer" target="_blank"> 🔗 London Design Award</a>
                    </h4>
                </div>
            );
    case 9:
        return (
            <div className='link-block'>
                <h4>
                <span>Related news/articles: </span>
                <a href="https://waymo.com/blog/2019/12/designing-new-transportation-reality-with-artcenter-college-of-design/" rel="noreferrer" target="_blank"> 🔗 Waymo Post</a>
                </h4>
            </div>
        );
    default:
        return null;
}
    
}

export default LinkBlock;
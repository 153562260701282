import React from 'react';
import { Link } from 'react-router-dom';

function ImageCard({title, description, image, link, icon}) {
    return (
        <Link to={`/${link}`}>
        <section className="imagecard">
            <div className='imagecard-description'>
                <div className='imagecard-description-icon'>
                <img src={icon} alt="asa" />
                </div>
                <h4>{title}</h4>
                <p>{description}</p>
            </div>
            <div>
                <img src={`./${image}`} alt={title} />
            </div>
        </section>
        </Link>
    );
}

export default ImageCard;